@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica';
  src: url('./fuentes/Helvetica.otf');
}
@font-face {
  font-family: 'Helvetica-light';
  src: url('./fuentes/Helvetica.ttf');
}
@font-face {
  font-family: 'Helvetica-bold';
  src: url('./fuentes/Helvetica-Bold.otf');
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  text-transform: uppercase;
}
textarea {
  text-transform: uppercase;
}

select {
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
select {
  -webkit-appearance: listbox !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='radio'] {
  accent-color: #007377;
}

.checkboxEspecial[type='checkbox']:before {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #808080;
  content: '';
  background: #fff;
}
.checkboxEspecialDeshabilitado[type='checkbox']:before {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #808080;
  content: '';
  background: #9f9f9f;
}
.checkboxEspecial[type='checkbox']:after {
  position: relative;
  display: block;
  left: 2px;
  width: 16px;
  height: 16px;
  content: '';
  top: -18px;
}

.checkboxEspecial.personalizado[type='checkbox']:checked:after {
  background-image: url('./imagenes/checkboxImagen.png'),
    linear-gradient(135deg, #b1b6be 0%, #fff 100%);
}
.checkboxEspecial.personalizado[type='checkbox']:not(
    :disabled
  ):checked:hover:after {
  background-image: url('./imagenes/checkboxImagen.png'),
    linear-gradient(135deg, #8bb0c2 0%, #fff 100%);
}

.valorNegativo {
  color: red !important;
}

.tablaFacturacion tr td:last-child {
  background: white;
}

.TablacierreDeCaja tr:last-child td:last-child {
  border-left: none;
}

.TablacierreDeCaja tr:last-child td:first-child {
  border-right: none;
}

.TablacierreDeCaja tr:last-child td {
  height: 100px;
}

.Tablaaudiometria {
  margin-bottom: 30px;
}

.Tablaaudiometria tbody tr {
  border: 1px solid #cfcfcf;
  margin: 0 !important;
  height: 50px;
}

.Tablaaudiometria tbody tr td:last-child {
  border-right: none;
}

.Tablaaudiometria tbody tr:first-child {
  border-bottom: none;
}

.Tablaaudiometria tbody tr:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Tablaaudiometria thead {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#oidoDerecho {
  background-color: #ffffff;
}

#oidoIzquierdo {
  background-color: #ffffff;
}
